import { conditionalSpread, rem } from 'clyne-core';
import NumberFlowReact from '@number-flow/react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';

import { getCurrencySymbol, getIntlLocales } from '../../helpers';

import { preciseLocationState, userState, workspaceModeState } from '../../state';

const NumberFlow = props => {
    const {
        font,
        value,
        prefix,
        suffix,
        discount,
        price = true,
    } = props;

    const user = useRecoilValue(userState);
    const workspaceMode = useRecoilValue(workspaceModeState);
    const preciseLocation = useRecoilValue(preciseLocationState);

    const locales = getIntlLocales({
        user,
        preciseLocation,
    });

    const currencySymbol = getCurrencySymbol({
        user,
        workspaceMode,
        preciseLocation,
    });

    return (
        <NumberFlowReact
            locales={locales}
            value={isNaN(value) ? 0 : value}
            {...conditionalSpread({
                prefix: prefix || `${currencySymbol?.symbol} `,
            }, (currencySymbol?.prefix && price) || !!prefix)}
            {...conditionalSpread({
                suffix: [
                    (suffix || ` ${currencySymbol?.symbol}`),
                    ...conditionalSpread([
                        `(${discount}%)`,
                    ], !!discount),
                ].filter(Boolean).join(' '),
            }, (currencySymbol?.suffix && price) || !!suffix || !!discount)}
            style={{
                ...conditionalSpread({
                    color: '#f87900',
                }, !!discount),
                ...conditionalSpread({
                    fontSize: rem(font?.size),
                }, !!font?.size),
                ...conditionalSpread({
                    fontWeight: font?.weight,
                }, !!font?.weight),
            }}
        />
    );
};

NumberFlow.propTypes = {
    font: PropTypes.shape({
        size: PropTypes.number,
        weight: PropTypes.number,
    }),
    value: PropTypes.number,
    price: PropTypes.bool,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    discount: PropTypes.number,
};

export default NumberFlow;
