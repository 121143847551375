import { AlignBoxTopCenterIcon, Cancel01Icon, EnteringGeoFenceIcon, Location01Icon, MaximizeScreenIcon, RepeatOne01Icon, Tick02Icon, VideoReplayIcon } from '@hugeicons/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { conditionalSpread } from 'clyne-core';
import classNames from 'classnames';
import { isBoolean } from 'lodash';
import PropTypes from 'prop-types';

import To from '../to';
import Tag from '../tag';
import Flex from '../flex';
import Icon from '../icon';
import Rating from '../rating';
import Slider from '../slider';
import Popover from '../popover';
import Metrics from '../metrics';
import Tooltip from '../tooltip';
import Favorite from '../favorite';
import Translate from '../translate';
import MarqueeLoop from '../marqueeLoop';
import ScreenPrice from '../screenPrice';
import LinkedScreen from '../linkedScreen';
import HorizontalScroll from '../horizontalScroll';
import ScreenPopularTimes from '../screenPopularTimes';
import ScreenCampaignActions from '../screenCampaignActions';

import useDevice from '../../hooks/useDevice';

import translate from '../../utils/translate';
import humanizeDurationWithTranslation from '../../utils/humanizeDurationWithTranslation';

import { constructScreenImagesForSlider, getLocationType, getScreenType, iconSize, idFormatter, roundDownToNearestMinute, screenURL, urlToModalHandler } from '../../helpers';

import { genericState, languageState, screenPreviewModalState } from '../../state';

import './index.scss';

const ScreenPaper = props => {
    const {
        data,
        locked,
        selected,
        onSelect,
        available,
        priceType,
        canRemove,
        highlighted,
        locateOnMap,
        locateOnList,
        priceAsToggle,
        setFocusedItem,
        checkForAvailable,
        supportModalPreview,
        canReduceVirtualSlot,
        appearance = 'default',
    } = props;

    const generic = useRecoilValue(genericState);
    const language = useRecoilValue(languageState);
    const setScreenPreviewModal = useSetRecoilState(screenPreviewModalState);

    const onMouseTimeoutRef = useRef(null);
    const onMouseTimeoutTriggeredRef = useRef(false);

    const [tooltipKey, setTooltipKey] = useState(0);

    const {
        isMobile,
    } = useDevice();

    const screenType = useMemo(() => getScreenType(data?.typeId, generic) || {}, [data?.typeId, generic]);

    const onUrlClick = useCallback(e => !!supportModalPreview && urlToModalHandler(e, () => setScreenPreviewModal({
        id: data?.id,
        name: data?.name,
        linked: data?.linked,
        comingSoon: data?.comingSoon,
        pendingForReview: data?.pendingForReview,
    })), [data?.id, data?.name, data?.linked, supportModalPreview, setScreenPreviewModal, data?.comingSoon, data?.pendingForReview]);

    const genericReplaceMap = useMemo(() => ({
        _SLOT_DURATION_: humanizeDurationWithTranslation(data?.slotDuration * 1000),
        _LOOP_DURATION_: humanizeDurationWithTranslation(roundDownToNearestMinute(data?.loopDuration * 1000)),
    }), [data?.slotDuration, data?.loopDuration, language]); // eslint-disable-line

    useEffect(() => {
        return () => {
            clearTimeout(onMouseTimeoutRef.current);
        };
    }, []);

    const detailsRenderer = useCallback(() => {
        const content = [
            ...conditionalSpread([
                {
                    icon: (
                        <VideoReplayIcon
                            style={iconSize(18)}
                        />
                    ),
                    children: genericReplaceMap._SLOT_DURATION_,
                },
                {
                    icon: (
                        <RepeatOne01Icon
                            style={iconSize(18)}
                        />
                    ),
                    children: genericReplaceMap._LOOP_DURATION_,
                },
            ], appearance !== 'default'),
            {
                label: data?.comingSoon ? (
                    <Translate>TBA</Translate>
                ) : `~${data?.averageDailyPlayback || 0}`,
                children: <Translate>Daily Rotations</Translate>,
            },
            {
                icon: (
                    <MaximizeScreenIcon
                        style={iconSize(18)}
                    />
                ),
                children: (
                    <Metrics
                        physicalWidth={data?.pWidth}
                        physicalHeight={data?.pHeight}
                    />
                ),
            },
            {
                icon: (
                    <Icon
                        size={18}
                        type={screenType.icon}
                    />
                ),
                children: translate(screenType.name),
            },
            {
                icon: (
                    <EnteringGeoFenceIcon
                        style={iconSize(18)}
                    />
                ),
                children: getLocationType(data?.placeTypeId, generic)?.name,
            },
        ].map((item, index) => (
            <Flex
                gap={6}
                key={index}
                className='sp-meta-detail-item'
                column={appearance === 'default'}
                stretch={appearance === 'default'}
            >
                {item.label ? (
                    <strong>{item.label}</strong>
                ) : item.icon}
                <p className='text-ellipsis'>
                    {item.children}
                </p>
            </Flex>
        ));

        return appearance === 'row' ? (
            <HorizontalScroll
                gap={16}
                alignItems='center'
            >
                {content}
            </HorizontalScroll>
        ) : (
            <div className='sp-meta-details-holder'>
                {content}
            </div>
        );
    }, [appearance, data, generic, screenType, genericReplaceMap]);

    const footerRenderer = useCallback(() => (
        <>
            <ScreenPrice
                data={data}
                externalValue={priceType}
                appearance={(priceAsToggle || !isNaN(priceType)) ? 'toggle' : appearance === 'column' ? 'dropDown' : 'default'}
                font={{
                    size: appearance === 'default' ? 15 : 14,
                }}
            />
            <Flex
                gap={12}
                stretch={false}
            >
                <Favorite
                    inline
                    tooltip
                    id={data?.id}
                />
                {!!locateOnMap && (
                    <Tooltip
                        position='top'
                        key={tooltipKey}
                        content={<Translate>Locate on Map</Translate>}
                    >
                        <button
                            onClick={() => {
                                setTooltipKey(val => val + 1);
                                locateOnMap(data?.latitude, data?.longitude);
                            }}
                            className='sp-meta-detail-action'
                        >
                            <Location01Icon
                                variant='solid'
                                style={iconSize(18)}
                            />
                        </button>
                    </Tooltip>
                )}
                <Popover
                    position='auto'
                    content={(
                        <ScreenPopularTimes
                            id={data?.id}
                            name={data?.name}
                        />
                    )}
                >
                    <button className='sp-meta-detail-action trigger-screen-popular-times'>
                        <svg
                            viewBox='0 0 24 24'
                            style={iconSize(18)}
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                fill='currentColor'
                                d='M10.85 1.25C7.48968 1.25 5.80953 1.25 4.52606 1.90396C3.39708 2.4792 2.4792 3.39709 1.90396 4.52606C1.25 5.80953 1.25 7.48969 1.25 10.85V13.15C1.25 16.5103 1.25 18.1905 1.90396 19.4739C2.4792 20.6029 3.39708 21.5208 4.52605 22.096C5.80953 22.75 7.48968 22.75 10.85 22.75H13.15C16.5103 22.75 18.1905 22.75 19.4739 22.096C20.6029 21.5208 21.5208 20.6029 22.096 19.4739C22.75 18.1905 22.75 16.5103 22.75 13.15V10.85C22.75 7.48969 22.75 5.80953 22.096 4.52606C21.5208 3.39709 20.6029 2.4792 19.4739 1.90396C18.1905 1.25 16.5103 1.25 13.15 1.25H10.85ZM8 8C8 7.44772 7.55228 7 7 7C6.44772 7 6 7.44772 6 8L6 16C6 16.5523 6.44772 17 7 17C7.55229 17 8 16.5523 8 16L8 8ZM12 10C12.5523 10 13 10.4477 13 11V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11C11 10.4477 11.4477 10 12 10ZM18 9C18 8.44772 17.5523 8 17 8C16.4477 8 16 8.44772 16 9V16C16 16.5523 16.4477 17 17 17C17.5523 17 18 16.5523 18 16V9Z'
                            />
                        </svg>
                        <Tooltip
                            position='top'
                            key={tooltipKey}
                            content={<Translate>Popular Times</Translate>}
                        >
                            <div
                                className='absolute-splash'
                                onClick={() => setTooltipKey(val => val + 1)}
                            />
                        </Tooltip>
                    </button>
                </Popover>
                {!!locateOnList && (
                    <Tooltip
                        position='top'
                        key={tooltipKey}
                        content={<Translate>Locate on List</Translate>}
                    >
                        <button
                            onClick={() => {
                                setTooltipKey(val => val + 1);
                                locateOnList(data?.id);
                            }}
                            className='sp-meta-detail-action'
                        >
                            <AlignBoxTopCenterIcon
                                variant='solid'
                                style={iconSize(18)}
                            />
                        </button>
                    </Tooltip>
                )}
                {appearance !== 'default' && appearance !== 'row' && (
                    <Rating rate={data?.rating} />
                )}
            </Flex>
            {appearance === 'column' && detailsRenderer()}
        </>
    ), [data, locateOnMap, detailsRenderer, appearance, tooltipKey, locateOnList, priceAsToggle, priceType]);

    return (
        <div
            onMouseEnter={() => {
                if (setFocusedItem) {
                    onMouseTimeoutRef.current = setTimeout(() => {
                        clearTimeout(onMouseTimeoutRef.current);
                        onMouseTimeoutTriggeredRef.current = true;
                        setFocusedItem({
                            id: data?.id,
                            source: locateOnList ? 'marker' : 'item',
                        });
                    }, 200);
                }
            }}
            onMouseLeave={() => {
                if (setFocusedItem) {
                    clearTimeout(onMouseTimeoutRef.current);
                    !!onMouseTimeoutTriggeredRef.current && setFocusedItem(val => val?.id ? {} : val);
                }
            }}
            className={classNames(
                'screen-paper-holder',
                'screen-price-switcher-active-hover',
                'slider-navigation-buttons-hover',
                `a-${appearance}`,
                {
                    highlighted,
                    'slider-navigation-buttons-reveal': appearance === 'column' && isMobile,
                }
            )}
        >
            <figure>
                <Slider
                    absolute
                    width='100%'
                    height='100%'
                    alt={data?.name}
                    touchDeviceBehaviour={appearance === 'column' && isMobile}
                    data={constructScreenImagesForSlider(data?.images)}
                    to={{
                        title: data?.name,
                        onTap: onUrlClick,
                        url: screenURL(data?.id),
                    }}
                />
                <ul className='sp-screen-slider-top'>
                    <li>
                        {(checkForAvailable && isBoolean(available)) && (
                            <div
                                className={classNames(
                                    'sp-screen-availability-status',
                                    {
                                        available,
                                    }
                                )}
                            >
                                {available ? (
                                    <>
                                        <Tick02Icon
                                            variant='solid'
                                            style={iconSize(16)}
                                        />
                                        <p>
                                            <Translate>Available</Translate>
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <Cancel01Icon
                                            variant='solid'
                                            style={iconSize(16)}
                                        />
                                        <p>
                                            <Translate>Unavailable</Translate>
                                        </p>
                                    </>
                                )}
                            </div>
                        )}
                    </li>
                    <li>
                        {(appearance === 'column' && !!onSelect) && (
                            <div className='sp-campaign-actions-holder'>
                                <ScreenCampaignActions
                                    data={data}
                                    locked={locked}
                                    onSelect={onSelect}
                                    selected={selected}
                                    available={available}
                                    canRemove={canRemove}
                                    checkForAvailable={checkForAvailable}
                                    canReduceVirtualSlot={canReduceVirtualSlot}
                                />
                            </div>
                        )}
                    </li>
                </ul>
                {appearance === 'default' && (
                    <div className='screen-paper-rating-holder'>
                        <Rating rate={data?.rating} />
                    </div>
                )}
            </figure>
            <div className='screen-paper-details-holder'>
                <Flex
                    column
                    gap={4}
                >
                    <Flex
                        gap={8}
                        justify='space-between'
                    >
                        <Flex
                            gap={8}
                            stretch={false}
                        >
                            <Tag
                                size={appearance === 'default' ? 'default' : 'small'}
                                onClick={onUrlClick}
                                url={screenURL(data?.id)}
                                name={idFormatter(data?.id)}
                                {...conditionalSpread({
                                    icon: {
                                        size: 16,
                                        type: 'icon-linked',
                                    },
                                    tooltip: (
                                        <LinkedScreen
                                            linked={data?.linked}
                                        />
                                    ),
                                }, data?.linked?.length)}
                            />
                            <To
                                onTap={onUrlClick}
                                title={data?.name}
                                url={screenURL(data?.id)}
                                className={classNames(
                                    'screen-paper-name',
                                    {
                                        'text-ellipsis': appearance === 'row',
                                    }
                                )}
                            >
                                {appearance === 'row' ? data?.name : (
                                    <MarqueeLoop>
                                        {data?.name}
                                    </MarqueeLoop>
                                )}
                            </To>
                        </Flex>
                        {(appearance !== 'column' && !!onSelect) && (
                            <ScreenCampaignActions
                                data={data}
                                locked={locked}
                                onSelect={onSelect}
                                selected={selected}
                                available={available}
                                canRemove={canRemove}
                                checkForAvailable={checkForAvailable}
                                canReduceVirtualSlot={canReduceVirtualSlot}
                            />
                        )}
                    </Flex>
                    {appearance === 'default' && (
                        <div className='screen-paper-heading-info'>
                            <Translate replaceMap={genericReplaceMap}>_SLOT_DURATION_ AD every _LOOP_DURATION_</Translate>
                        </div>
                    )}
                </Flex>
                <div className='v-divider' />
                {appearance !== 'column' && (
                    <>
                        {detailsRenderer()}
                        <div className='v-divider' />
                    </>
                )}
                {appearance === 'column' ? (
                    <HorizontalScroll
                        gap={16}
                        alignItems='center'
                    >
                        {footerRenderer()}
                    </HorizontalScroll>
                ) : (
                    <Flex justify='space-between'>
                        {footerRenderer()}
                    </Flex>
                )}
            </div>
        </div>
    );
};

ScreenPaper.propTypes = {
    data: PropTypes.object,
    locked: PropTypes.bool,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    available: PropTypes.bool,
    priceType: PropTypes.number,
    canRemove: PropTypes.bool,
    appearance: PropTypes.oneOf([
        'default',
        'column',
        'row',
    ]),
    highlighted: PropTypes.bool,
    locateOnMap: PropTypes.func,
    priceAsToggle: PropTypes.bool,
    setFocusedItem: PropTypes.func,
    checkForAvailable: PropTypes.bool,
    supportModalPreview: PropTypes.bool,
    canReduceVirtualSlot: PropTypes.bool,
};

export default ScreenPaper;
